import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import MyNavbar from "./MyNavbar";

function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Implement your form submission logic here, such as sending the data to an API
        console.log(formData);
    };

    return (
        <div>
            <MyNavbar isVisible={true}/>
            <Container>
            <Row>
                <Col lg="12">
                    <h2>Contact Us</h2>
                    <p>Fill in the form below to send us a message.</p>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input type="text" name="name" id="name" placeholder="Your Name" value={formData.name} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input type="email" name="email" id="email" placeholder="Your Email" value={formData.email} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="message">Message</Label>
                            <Input type="textarea" name="message" id="message" placeholder="Your Message" value={formData.message} onChange={handleChange} />
                        </FormGroup>
                        <Button type="submit">Send Message</Button>
                    </Form>
                </Col>
                <Col lg="6">
                    <h4>Our Office</h4>
                    <p>Brief description of your office location, perhaps include an address and a phone number.</p>
                    <h4>Working Hours</h4>
                    <p>Brief description of your working hours.</p>
                    {/* Here, you should include an image of your office or team. Make sure the alt text describes the image well for accessibility. */}
                    <img src="path-to-your-image.jpg" alt="Our office building" style={{ width: '100%', height: 'auto' }} />
                </Col>
            </Row>
        </Container>
        </div>
    );
}

export default ContactUs;
