
import { Link } from 'react-router-dom';


const HomeHero = () => {

    // Inline style for the hero background
    const heroStyle = {
        backgroundImage: 'url(/img/hero1.gif)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // padding: '100px 0', // You may adjust this as necessary
        height: '100vh', // Make the hero take up full viewport height
        width: '100vw', // Ensure the hero covers full viewport width
        display: 'flex', // Enable flex to center the text vertically
        justifyContent: 'center', // Center the child horizontally
        alignItems: 'center', // Center the child vertically
    };

    const textStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background for text
        padding: '20px',
        borderRadius: '10px',
        color: 'white',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Soften the text shadow
    };


    return (
            <div style={heroStyle} className="text-center">
                <div style={textStyle}>
                    <h1>Electric Elk</h1>
                    <p>Velomobiles. Made in Chicago... with Love.</p>
                    <Link to="/product-details" className="btn btn-light">Learn More</Link>

                </div>
            </div>
    );
};

export default HomeHero;
