import React, { useState, useEffect } from 'react';

import MyNavbar from "./MyNavbar";

const StickyNavbar = () => {
    const [isVisible, setIsVisible] = useState(false); // Initially not visible

    useEffect(() => {
        console.log('StickyNavbar.isVisible:', isVisible);
        const handleScroll = () => {
            // Set the navbar to be visible after scrolling down 100px
            const shouldBeVisible = window.scrollY > 100;
            if (shouldBeVisible !== isVisible) {
                setIsVisible(shouldBeVisible);
            }
        };

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isVisible]);

    return (
        <MyNavbar isVisible={isVisible} isFixedTop={true} />
    );
};

export default StickyNavbar;
