import {Card, CardBody, CardText, CardTitle, Col, Row} from "reactstrap";

const Features = () => {
    return(
        <section className="my-5">
            <h2>Benefits and Features</h2>
            <Row>
                {/* Repeat for each feature */}
                <Col md="4">
                    <Card>
                        <CardBody>
                            <CardTitle tag="h5">Eco-Friendly Transportation</CardTitle>
                            <CardText>Velomobiles offer a sustainable mode of travel, reducing carbon footprint by utilizing human power augmented with electric assistance.</CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="4">
                    <Card>
                        <CardBody>
                            <CardTitle tag="h5">Weather Protection</CardTitle>
                            <CardText>Unlike traditional bicycles, velomobiles come with a streamlined body that shields riders from rain, wind, and even sun, making all-weather commuting possible.</CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="4">
                    <Card>
                        <CardBody>
                            <CardTitle tag="h5">Enhanced Safety Features</CardTitle>
                            <CardText>With its low-profile design and sturdy frame, velomobiles provide improved stability and visibility on the road, including integrated lighting systems for night-time visibility.</CardText>
                        </CardBody>
                    </Card>
                </Col>
                {/* Add more columns for more features */}
            </Row>
        </section>
    )
}

export default Features;