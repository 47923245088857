import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebaseConfig'; // Assuming you have this export from your Firebase setup

const ContactModal = ({ isOpen, toggle }) => {

    const [formData, setFormData] = useState({ name: '', email: '', message: '' });



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Adding a new document to the 'leads' collection
            const docRef = await addDoc(collection(db, "leads"), {
                to: ["trevor@electricelk.co"],
                message: {
                    subject: "New Inquiry for Electric Elk!",

                    text: formData.message,
                },
                name: formData.name,
                from: "trevor@electricelk.co",
                replyTo: formData.email,
                timestamp: new Date(),
                reviewed: false
            });
            console.log("Document written with ID: ", docRef.id);
            // After submitting, you might want to close the modal
            toggle();
            // Reset form data
            setFormData({ name: '', email: '', message: '' });
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };


    return (
        <>

            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Contact Us</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input type="text" name="name" id="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input type="email" name="email" id="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="message">Message</Label>
                            <Input type="textarea" name="message" id="message" placeholder="Your Message" value={formData.message} onChange={handleChange} required />
                        </FormGroup>
                        <Button type="submit" color="primary">Send Message</Button>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default ContactModal;
