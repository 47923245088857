import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
    return (
        <footer className="bg-light text-center text-lg-start">
            <Container fluid>
                <Row>
                    <Col className="text-center py-3">
                        © {new Date().getFullYear()} Electric Elk LLC. All rights reserved.
                        <br />
                        A133 and A133mk1 are trademarks of Electric Elk LLC.
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
