import React from 'react';
import { Container, Row, Col, Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap';

import MyNavbar from "./MyNavbar";

const AboutUs = () => {
    return (
        <div>
            <MyNavbar isVisible={true}/>
            <Container>

                <Row className="my-5">
                    <Col>
                        <h2>About Us</h2>
                        <p><i>We Believe in...</i></p>
                        <p>
                            <strong>A healthier planet -</strong> We believe
                            that the future of transportation is electric. We
                            are committed to reducing our carbon footprint and
                            helping others do the same.
                        </p><p>
                        <strong>Safer Streets, for Everyone -</strong>At the
                        heart of our mission lies a steadfast commitment to
                        creating safer streets for all. We believe that every
                        journey, whether it's a short commute or a leisurely
                        ride, should be an experience free from worry.
                    </p><p>
                        <strong>Financial Independence - </strong>We believe
                        that true freedom comes when individuals can navigate
                        the world without the burden of excessive costs tied to
                        traditional car ownership.
                    </p><p>
                        <strong>A better tomorrow -</strong> We believe in
                        fostering compassion, championing innovation, and
                        nurturing community. Our dedication to sustainability
                        and equality fuels every endeavor, as we strive to pave
                        the path for a world where every individual's dreams can
                        flourish.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <Card>
                            <CardImg top width="100%"
                                     src="/path/to/your-image.jpg"
                                     alt="Our Story Image"/>
                            <CardBody>
                                <CardTitle tag="h5">Our Story</CardTitle>
                                <CardText>In the heart of Chicago's unforgiving
                                    winter, a transformative journey began. The
                                    founder, a once car-reliant individual,
                                    found himself redefining his relationship
                                    with transportation after a stroke altered
                                    his life's course. The icy winds of change
                                    led him to trade the steering wheel for
                                    handlebars, embracing the world of cycling.
                                    Yet, even as he pedaled through the frigid
                                    cityscape, he couldn't escape the bitter
                                    truth—biking through a Chicago winter was
                                    far from ideal.

                                    An engineer by day, the founder's mind
                                    ignited with innovation. He recognized a
                                    need to protect riders from the harsh grasp
                                    of winter's chill. Armed with his vision, he
                                    took a leap of faith and commissioned
                                    prototypes from Alibaba, determined to craft
                                    a solution tailored to Chicago's wintry
                                    embrace. Through careful modifications and
                                    relentless perseverance, he transformed
                                    these prototypes into steadfast companions,
                                    resilient against the elements.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <CardImg top width="100%"
                                     src="/path/to/your-image.jpg"
                                     alt="Innovation and Inspiration Image"/>
                            <CardBody>
                                <CardTitle tag="h5">Innovation and
                                    Inspiration</CardTitle>
                                <CardText>As his ties to the cycling community
                                    deepened, so did his awareness of a greater
                                    dilemma. Cars, once perceived as convenient
                                    modes of transport, were now cast in a
                                    different light—an environmental threat and
                                    a harrowing danger to young lives. With a
                                    newborn son in his arms, the founder's
                                    purpose crystallized. His commitment to
                                    creating a safer world for his child became
                                    his driving force, transcending personal
                                    boundaries.

                                    He recognized that the path to safeguarding
                                    his son's future lay in reshaping the urban
                                    landscape, and to do so, an alternative to
                                    year-round vehicular dependence had to
                                    thrive. It was within this fervor for change
                                    that the A133 was born—an embodiment of
                                    resilience, innovation, and a father's
                                    unwavering dedication to a brighter
                                    tomorrow. This single model, a marvel of
                                    all-weather micromobility, stands as a
                                    testament to his belief that by offering a
                                    sustainable and practical choice, we can
                                    pave the way for a world less burdened by
                                    the weight of car culture.</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AboutUs;
