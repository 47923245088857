import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import TrikeForSalePage from "./forsale/TrikeForSalePage";

import MyNavbar from "./MyNavbar";

const CurrentlyAvailable = () => {
    const { id } = useParams(); // This hooks allows us to access the URL parameter
    const [trikeInfo, setTrikeInfo] = useState(null);

    useEffect(() => {
        // Asynchronously fetch the JSON data
        import('./forsale/available.json')
            .then(data => {
                // Assuming data is the object containing all trikes keyed by their ids
                if (data[id]) {
                    setTrikeInfo(data[id]);
                } else {
                    console.log("Trike not found");
                }
            })
            .catch(error => console.log("Failed to fetch trike data", error));
    }, [id]); // This effect should run whenever the `id` changes

    return (
        <div>
            <MyNavbar isVisible={true} isFixedTop={false} />
        <Container>
            {trikeInfo ? (
                <Row>
                    <Col>
                        <TrikeForSalePage trike={trikeInfo} />
                    </Col>
                </Row>
            ) : (
                <div>Loading...</div>
            )}
        </Container>
        </div>
    );
};

export default CurrentlyAvailable;
