import React, {useState} from 'react';
import { Container, Row, Col, Button, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import MyNavbar from "./MyNavbar";
import ContactModal from './ContactModal'; // Import your ContactModal component


const specifications = [
    {
        title: "Motor Wattage",
        desc: "Unrestricted: 1000W, Restricted: 500W"
    },
    {
        title: "Battery",
        desc: "48V 30Ah (1.5KwH) Lithium Phosphate (LiFePO4)"
    },
    {
        title: "Range",
        desc: "Typically ~30 miles. Range is dependent on rider/cargo weight," +
            " pedal assist, and weather/temperature, as well as battery age."
    }
    ];

const ProductDetails = () => {
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    return (
        <div>
            <MyNavbar isVisible={true}/>
            <Container>
            <Row className="my-5">
                <Col md="6">
                    {/* Product Image */}
                    <Card>
                        <CardImg top width="100%" src="/img/a133-profile.jpg" alt="Product Image - Brief description of the image" />
                    </Card>
                </Col>
                <Col md="6">
                    {/* Product Information */}
                    <CardBody>
                        <CardTitle tag="h5">A133mk1</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Product Category</CardSubtitle>
                        <CardText>
                            The A133mk1 cargo velomobile redefines urban commuting with its cutting-edge design and unparalleled functionality. Crafted for the modern city dweller, the A133mk1 combines the agility of a bicycle with the carrying capacity of a small car, making it the ultimate eco-friendly commuter vehicle. Its streamlined shape not only provides a fast and efficient ride but also ensures stability and safety in urban traffic. The spacious cargo compartment is ingeniously integrated into its sleek design, allowing for secure and convenient transport of groceries, packages, or even a day's worth of business supplies. With its electric assist feature, navigating through bustling city streets or tackling steep inclines becomes effortless, ensuring you arrive at your destination fresh and on time, every time.
                        </CardText>
                        <CardText>
                            Understanding that individual needs vary, we offer the ability to customize the A133mk1 to your exact specifications. From the color and finish to the gear configurations and electric assist options, you can design a velomobile that not only meets your commuting needs but also reflects your personal style. For those eager to hit the road without delay, we also have a selection of pre-configured models available for immediate purchase. Each model is built with the same attention to detail and quality craftsmanship, ready to revolutionize your daily commute. Explore our range today and embark on a greener, more efficient, and enjoyable commuting experience with the A133mk1 cargo velomobile.
                        </CardText>

                        <Button onClick={toggleModal}>Pre-Order Now</Button>&nbsp;<Button>Browse Currently Available</Button>
                        <ContactModal isOpen={modal} toggle={toggleModal} />
                    </CardBody>
                </Col>
            </Row>

            <Row className="my-3">
                <Col md="4">
                    {/* Feature 1 */}
                    <Card>
                        <CardImg top width="100%" src="/path-to-feature1-image.jpg" alt="Feature 1 Image - Describe the feature visually" />
                        <CardBody>
                            <CardTitle tag="h5">Marine Vinyl Canopy</CardTitle>
                            <CardText>A Marine Vinyle Canopy created by&nbsp;
                                <a href={"https://chicagomarinecanvas.com/"}>Chicago Canvas</a>
                                &nbsp;to keep you dry and
                                shielded from the Windy City's most viscous winds.</CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="4">
                    {/* Feature 2 */}
                    <Card>
                        <CardImg top width="100%" src="/path-to-feature2-image.jpg" alt="Feature 2 Image - Describe the feature visually" />
                        <CardBody>
                            <CardTitle tag="h5">Full Light Kit</CardTitle>
                            <CardText>Parking lights. Tail lights. Brake Lights. Running Lights.
                                Turn Signals (front and rear). See and be Seen.</CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="4">
                    {/* Feature 3 */}
                    <Card>
                        <CardImg top width="100%" src="/path-to-feature3-image.jpg" alt="Feature 3 Image - Describe the feature visually" />
                        <CardBody>
                            <CardTitle tag="h5">Rear View Mirror/Dash Camera</CardTitle>
                            <CardText>Not your grandfather's rear view mirror. In addition
                                to seeing traffic coming up behind you, the integrated front
                                and rear cameras keep a record of events, should you ever
                                need to review them.</CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {/* More sections can be added following the structure above for reviews, FAQs, etc. */}
            <Row>
                <Col>
                    {/* Specifications */}
                    <h3>Specifications</h3>
                    {specifications.map((spec, index) => (
                        <p key={index}>
                            <strong>{spec.title}:</strong> {spec.desc}
                        </p>
                    ))}
                </Col>
            </Row>
        </Container>
        </div>
    );
};

export default ProductDetails;
