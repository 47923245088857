import {Card, CardImg, Col, Row} from "reactstrap";
import React from "react";


const WhatIsIt = () => {
    return (

        <div>
            <section className="my-5">
                <Row>
                    <Col md="6">
                        <Card>
                            <CardImg top width="100%" src="/img/a133-snow-alley.jpg" alt="Product image" />
                        </Card>
                    </Col>
                    <Col md="6">
                        <h2>What is a cargo velomobile?</h2>
                        <p>A velomobile is an innovative, pedal-powered vehicle that marries the essence of cycling with the protective features of a car, offering an eco-friendly alternative to traditional motorized transport. Designed for efficiency and speed, velomobiles are enclosed tricycles that provide a streamlined, aerodynamic body to minimize air resistance and maximize performance. This unique design not only enhances speed but also shields the rider from adverse weather conditions, making it a practical choice for daily commuting. With the addition of electric assist options, velomobiles can tackle a wider range of terrains and distances, making them a versatile choice for eco-conscious individuals.
                        </p><p>
                        Cargo velomobiles extend this concept further by incorporating a spacious cargo area, allowing users to transport goods effortlessly. This makes them an ideal solution for small-scale deliveries, shopping trips, or even adventurous outings requiring extra gear. By combining the health benefits of cycling with the utility of a small vehicle, cargo velomobiles present a sustainable and efficient transportation option. Whether you're looking to reduce your carbon footprint, bypass traffic congestion, or simply enjoy a more active lifestyle, a cargo velomobile offers a compelling blend of functionality, sustainability, and fun.</p>
                    </Col>
                </Row>
            </section>
        </div>

  )
}

export default WhatIsIt;