import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
import MyNavbar from "./MyNavbar";

const TrikeList = () => {
    const [trikes, setTrikes] = useState(null); // Start with null to indicate no data has been loaded

    useEffect(() => {
        import('./forsale/available.json')
            .then(data => {
                // Convert data to an array if it's not one
                setTrikes(Object.values(data).slice(0, -1));

            })
            .catch(error => {
                console.error("Failed to fetch trikes data", error);
                setTrikes([]); // Set to an empty array on error to handle gracefully
            });
    }, []);

    if (!trikes) {
        // Optionally display a loading spinner or message here
        return <div>Loading...</div>;
    }

    return (
        <div>
            <MyNavbar isVisible={true} isFixedTop={false} />

        <Container>
            <Row>
                {trikes.map(trike => (
                    <Col md={4} key={trike.id} className="mb-4">
                        <Card>
                            <CardImg top width="100%" src={trike.images && trike.images[0]} alt={trike.name} />
                            <CardBody>
                                <CardTitle tag="h5">
                                    <Link to={`/currently-available/${trike.id}`}>{trike.name}</Link>
                                </CardTitle>
                                <CardText>
                                    {trike.description && trike.description.length > 100 ? `${trike.description.substring(0, 100)}...` : trike.description}
                                </CardText>
                                <CardText className="text-muted">
                                    Price: ${trike.price}
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
        </div>
    );
};

export default TrikeList;
