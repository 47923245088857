import {
    Container,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink
} from "reactstrap";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import ContactModal from "./ContactModal";


const MyNavbar = ({isVisible, isFixedTop}) => {
    const fixedTop = isFixedTop ? 'fixed-top' : '';
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    return (
        <Navbar color="light" light expand="md" className={`${fixedTop} ${isVisible ?
         'show' : 'd-none'}`}>
            <Container>
                <NavbarBrand href="/">Electric Elk</NavbarBrand>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink tag={Link} to="/">Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to="/about-us">About Us</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to="/product-details">Product Details</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to="/faq">FAQ</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to="/currently-available">Currently Available</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={toggleModal}>Contact Us</NavLink>
                    </NavItem>
                    <ContactModal isOpen={modal} toggle={toggleModal} />
                </Nav>
            </Container>
        </Navbar>
    )
}

export default MyNavbar;
