import React, {useState} from 'react';
import { Container, Row, Col, Carousel, ListGroup, Card, Button } from 'react-bootstrap';
import ContactModal from "../ContactModal";

const TrikeForSalePage = ({ trike }) => {
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    return (
        <Container className="my-5">
            <Row>
                <Col md={6}>
                    <Carousel>
                        {trike.images.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    className="d-block w-100"
                                    src={image}
                                    alt={`${trike.name} view ${index + 1}`}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                <Col md={6}>
                    <h1>{trike.name}</h1>
                    <p>{trike.description}</p>
                    <ListGroup.Item>
                        <ListGroup.Item key={"model"}><strong>Model:</strong> {trike.modelYear} {trike.model}</ListGroup.Item>
                        <ListGroup.Item key={"motorsize"}><strong>Motor Size:</strong> {trike.motorSize}</ListGroup.Item>
                        <ListGroup.Item key={"batteryCapacity"}><strong>Battery:</strong> {trike.voltage}v x {trike.batteryCapacity}Ah {trike.batteryChemistry}</ListGroup.Item>
                        <ListGroup.Item key={"range"}><strong>Avg Range:</strong> {trike.range} miles (varies based on load and temperature)</ListGroup.Item>
                        <ListGroup.Item key={"topSpeed"}><strong>Top Speed:</strong> {trike.topSpeed} mph</ListGroup.Item>
                        <ListGroup.Item key={"price"}><strong>Price:</strong> ${trike.price}</ListGroup.Item>
                        <ListGroup.Item key={"mileage"}><strong>Mileage:</strong> {trike.mileage} miles</ListGroup.Item>
                        <ListGroup.Item key={"payload"}><strong>Max Payload:</strong> {trike.payload} kgs / {trike.payload * 2.20462} lbs</ListGroup.Item>

                        {/* Add more standard specifications as needed */}
                        {trike.additionalSpecs.map((spec, index) => (
                            <ListGroup.Item key={index}><strong>{spec.title}:</strong> {spec.detail}</ListGroup.Item>
                        ))}
                    </ListGroup.Item>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Card>
                        <Button onClick={toggleModal}>Contact Sales</Button>
                        <ContactModal isOpen={modal} toggle={toggleModal} />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default TrikeForSalePage;
