import React from 'react';
import { Container} from 'reactstrap';
import HomeHero from "./HomeHero";
import WhatIsIt from "./WhatIsIt";
import Features from "./Features";
import StickyNavbar from "./StickyNavbar";

const HomePage = () => {
    return (
        <div>
            <StickyNavbar />
            {/* Hero Section */}
            <HomeHero />
        <Container fluid>




            <WhatIsIt />

            {/* Benefits and Features */}
            <Features />

            {/* How It Works */}
            <section className="my-5">
                <h2>How It Works</h2>
                <p>Step-by-step explanation or video demonstration.</p>
            </section>

            {/* Footer or additional sections go here */}

        </Container>
        </div>
    );
};

export default HomePage;
