import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import AboutUs from './AboutUs';
import ProductDetails from './ProductDetails';
import FAQ from './FAQ';
import ContactUs from './ContactUs';
import CurrentlyAvailable from "./CurrenltyAvailable";

import './App.css';
import Footer from "./Footer";
import TrikeList from "./TrikeList";

const App = () => {
    console.log('App loaded.')
  return (
      <div>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/about-us" element={<AboutUs/>} />
          <Route path="/product-details" element={<ProductDetails/>} />
          <Route path="/faq" element={<FAQ/>} />
          <Route path="/currently-available/:id" element={<CurrentlyAvailable/>} />
          <Route path="/currently-available" element={<TrikeList />} />
          <Route path="/contact" element={<ContactUs/>} />
          {/* You can add more routes here */}
        </Routes>
          <Footer />
      </div>
  );
};

export default App;
