// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyA8Xo_tn-thEhIaTmIGEnhpsGXEm3Y0138",
    authDomain: "eelkwebsite.firebaseapp.com",
    projectId: "eelkwebsite",
    storageBucket: "eelkwebsite.appspot.com",
    messagingSenderId: "1021150470400",
    appId: "1:1021150470400:web:04decd24ec7aa32b1053e5",
    measurementId: "G-LQYQXGTNFE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a Firestore instance
const db = getFirestore(app);

export { db };
