import React, { useState } from 'react';
import { Collapse, CardBody, Card, Button, Container, Row, Col } from 'reactstrap';
import MyNavbar from "./MyNavbar";

const faqs = [
    {
        question: "What problem does a velomobile solve?",
        answer: "A velomobile addresses several pressing issues faced by urban commuters and environmentally conscious individuals. At its core, it offers an efficient, sustainable alternative to traditional motor vehicles and public transportation, reducing reliance on fossil fuels and lowering the carbon footprint of its users. By integrating the physical benefits of cycling with the comfort and speed of an enclosed vehicle, velomobiles tackle the problem of maintaining an active lifestyle amidst a busy schedule. They also provide a viable solution to the challenges of navigating congested city streets, offering a nimble and more enjoyable commuting experience that bypasses traffic jams and parking hassles." +
            "Moreover, velomobiles are designed to overcome the limitations" +
            " of weather and seasonality that often discourage cycling." +
            " Their aerodynamic, enclosed structure shields riders from rain, wind, and cold, making commuting feasible and comfortable in various weather conditions. In terms of cargo transportation, cargo velomobiles specifically address the issue of carrying goods efficiently and securely without the need for a car, facilitating everything from daily errands to small business deliveries. In essence, velomobiles merge the health, environmental, and practical benefits of cycling with the comfort and utility of motor vehicles, offering a comprehensive solution for today's mobility challenges."
    },
    {
        question: "Where can I use a velomobile?",
        answer: "As a class 2 e-bike you can use a velomobile anywhere you" +
            " can ride a class 2 e-bike, including bike lanes. The A133" +
            " frame was specifically designed to fit comfortably into a" +
            " standard Chicago bike lane."
    },
    {
        question: "Who can benefit from a velomobile?",
        answer: "Cargo velomobiles are a versatile transportation solution that can benefit a wide range of individuals and businesses. Ideal for eco-conscious commuters looking to reduce their carbon footprint, these vehicles provide a reliable, efficient, and fun way to navigate through city streets while bypassing traffic congestion. Additionally, families can enjoy the convenience of a cargo velomobile for school runs, grocery shopping, or leisurely weekend outings, thanks to its ample storage space.\n" +
            "\n" +
            "Small business owners and entrepreneurs, particularly those in delivery services, catering, or mobile businesses, will find the cargo velomobile an invaluable asset. Its cargo capacity enables the transport of goods in a cost-effective and environmentally friendly manner. Furthermore, fitness enthusiasts who prefer an active lifestyle can use cargo velomobiles as a workout tool that integrates seamlessly into their daily routines, offering both a physical challenge and practical utility."
    },
    {
        question: "What 'class' of e-bike is the A133?",
        answer: "The A133 is a Class 2 e-bike, under the traditional" +
            " three-class system. This means that it has a motor that" +
            " propels the bike, but it is limited to a speed of 20 mph, and " +
            "that it can be propelled by pedal assist OR throttle. However" +
            " the restrictor is 'European' so it is actually limited to 25" +
            " km/h, or ~11mph."
    }
    // Add more FAQs as needed
];

const FAQSection = () => {
    const [open, setOpen] = useState(null);

    const toggle = (i) => {
        if (open === i) {
            setOpen(null);
        } else {
            setOpen(i);
        }
    };

    return (
        <div>
           <MyNavbar isVisible={true}/>
           <Container>
            <Row>
                <Col>
                    <h2>Frequently Asked Questions</h2>
                    {faqs.map((faq, index) => (
                        <Card key={index} className="mb-3">
                            <CardBody>
                                <Button color="link" onClick={() => toggle(index)} style={{ marginBottom: '1rem' }}>
                                    {faq.question}
                                </Button>
                                <Collapse isOpen={open === index}>
                                    <Card>
                                        <CardBody>
                                            {faq.answer}
                                        </CardBody>
                                    </Card>
                                </Collapse>
                            </CardBody>
                        </Card>
                    ))}
                </Col>
            </Row>
        </Container>
        </div>
    );
};

export default FAQSection;
